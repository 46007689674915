import React from "react"
import { graphql } from "gatsby"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"
import Carousel, { CarouselImages } from "Components/Carousel"

interface LiamMylesIndentProps {
  data: CarouselImages
}

const LiamMylesIndent: React.FC<LiamMylesIndentProps & GatsbyProps> = ({
  data: { smallestImages, smallImages, mediumImages, largeImages },
  location,
}) => (
  <StandardLayout location={location}>
    <SEO title="Liam Myles Indent" />
    <h1>Liam Myles Indent</h1>
    <p>
      Using Cinema 4D I created a 3D animated ident for myself. To create the
      animation I used dynamic physics to simulate the ball rolling and the
      dominoes toppling. The rest of the animations were achieved using various
      tools in Cinema 4D. I then created the glass shatter effect in After
      Effects and cut it all together with audio in Premiere.
    </p>
    <p>
      This was part of a 3D module in university, I also did some 3D Renders
      which you can see below
    </p>
    <h2>3D Indent animation</h2>
    <ResponsiveIFrame src="https://player.vimeo.com/video/84554146" />
    <h2>3D Renders</h2>
    <Carousel
      smallestImages={smallestImages}
      smallImages={smallImages}
      mediumImages={mediumImages}
      largeImages={largeImages}
      altTexts={[
        "Shield with twin swords",
        "Twin Swords on Altar",
        "Swords on alter with shield",
      ]}
    />
  </StandardLayout>
)

export const query = graphql`
  query {
    smallestImages: allFile(
      filter: { relativePath: { glob: "art/*3d-model-project*" } }
    ) {
      edges {
        node {
          ...CarouselSmallestImage
        }
      }
    }
    smallImages: allFile(
      filter: { relativePath: { glob: "art/*3d-model-project*" } }
    ) {
      edges {
        node {
          ...CarouselSmallImage
        }
      }
    }
    mediumImages: allFile(
      filter: { relativePath: { glob: "art/*3d-model-project*" } }
    ) {
      edges {
        node {
          ...CarouselMediumImage
        }
      }
    }
    largeImages: allFile(
      filter: { relativePath: { glob: "art/*3d-model-project*" } }
    ) {
      edges {
        node {
          ...CarouselLargeImage
        }
      }
    }
  }
`
export default LiamMylesIndent

import React from "react"

import { graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { GraphqlEdgeNode } from "Src/global-types"

import styled from "styled-components"

const CarouselWrapper = styled.section`
  display: flex;
  justify-content: center;
  ul {
    display: flex;
    overflow: scroll;
    max-width: 1000px;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    li {
      scroll-snap-align: start;
    }
    width: 300px;
    .image-sizing {
      width: 300px;
    }
    @media (min-width: 500px) and (max-width: 699px) {
      width: 500px;
      .image-sizing {
        width: 500px;
      }
    }
    @media (min-width: 700px) and (max-width: 999px) {
      width: 700px;
      .image-sizing {
        width: 700px;
      }
    }
    @media (min-width: 1000px) {
      width: 1000px;
      .image-sizing {
        width: 960px;
      }
    }
  }
`

export interface FluidImage {
  childImageSharp: {
    fluid: FluidObject
  }
}

export interface CarouselImages {
  smallestImages: GraphqlEdgeNode<FluidImage>
  smallImages: GraphqlEdgeNode<FluidImage>
  mediumImages: GraphqlEdgeNode<FluidImage>
  largeImages: GraphqlEdgeNode<FluidImage>
}

interface CarouselProps {
  altTexts: Array<string>
}

const Carousel: React.FC<CarouselProps & CarouselImages> = ({
  smallestImages,
  smallImages,
  mediumImages,
  largeImages,
  altTexts,
}) => {
  const smallest = smallestImages.edges.map(({ node }) => ({
    ...node.childImageSharp.fluid,
    media: "(max-width: 699px)",
  }))
  const small = smallImages.edges.map(({ node }) => ({
    ...node.childImageSharp.fluid,
    media: "(max-width: 699px)",
  }))
  const medium = mediumImages.edges.map(({ node }) => ({
    ...node.childImageSharp.fluid,
    media: "(min-width: 700px) and (max-width: 999px)",
  }))
  const large = largeImages.edges.map(({ node }) => ({
    ...node.childImageSharp.fluid,
    media: "(min-width: 1000px)",
  }))
  const allSizedImages = smallImages.edges.map((empty, index) => {
    return [
      { ...large[index] },
      { ...medium[index] },
      { ...small[index] },
      { ...smallest[index] },
    ]
  })
  return (
    <CarouselWrapper>
      <ul>
        {allSizedImages.map((images, index) => (
          <li key={`${index}-carousel`}>
            <Img
              fluid={images}
              alt={altTexts[index]}
              className="image-sizing"
            />
          </li>
        ))}
      </ul>
    </CarouselWrapper>
  )
}

export const query = graphql`
  fragment CarouselSmallestImage on File {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment CarouselSmallImage on File {
    childImageSharp {
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment CarouselMediumImage on File {
    childImageSharp {
      fluid(maxWidth: 700, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment CarouselLargeImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default Carousel
